#calendar {
    display: flex;
    height: 100%;
    margin-bottom: -$content-padding;
}

.fc-groupoverview {
    .fc.fc-unthemed.test-class {
        width: calc(100% + #{$content-padding * 2});

        .fc-view {
            overflow-x: auto;
            width: 100%;
            max-width: 100%;
        }

        .fc-view > table {
            width: auto;
            min-width: 100%;
        }

        .fc-day,
        .fc-resource-cell {
            width: 20vw !important;
        }

        .fc-time-grid .fc-slats {
            z-index: 4;
            pointer-events: none;
        }

        .fc-scroller.fc-time-grid-container {
            overflow: initial !important;
        }

        .fc-axis {
            position: sticky;
            left: 0;
            background-color: $white;
        }
    }
}

.fc.fc-unthemed {
    margin-left: -$content-padding;
    margin-right: -$content-padding;

    .fc-toolbar {
        padding-left: $content-padding;
        padding-right: $content-padding;

        h2 {
            font-weight: 700;
            font-size: 18px;
            color: $subsidiary;
        }

        .fc-button {
            padding-top: 0.2em;
            padding-bottom: 0.2em;
            padding-left: 0.5em;
            padding-right: 0.5em;

            > .fc-icon {
                font-size: 1.3em;
                position: relative;
                top: -1px;
            }
        }

        &.fc-header-toolbar {
            margin-bottom: $content-padding;
        }
    }

    .fc-button-group {
        box-shadow: 0 1px 2px rgba($gray, 0.45);
    }

    .fc-button {
        background-color: $white;
        border-color: $gray-lighter;
        color: $gray-darker;
        font-weight: 600;
        font-size: 14px;

        &:hover,
        &:focus {
            background-color: $gray-lightest;
            border-color: $gray;
            color: $gray-darker;
        }

        &:focus {
            box-shadow: 0 0 0 0.2rem rgba($gray, 0.8) !important;
        }

        &.fc-button-active {
            color: $white;
            background-color: $primary;
            border-color: $primary;
        }
    }

    th,
    td,
    thead,
    tbody {
        border-color: rgba($subsidiary, 0.1);
    }

    td,
    th {
        &:first-child {
            border-left-width: 0;
        }

        &:last-child {
            border-right-width: 0;
        }

        &.fc-today {
            background-color: rgba($subsidiary, 0.05) !important;
            border-right-color: rgba($subsidiary, 0.2);
            border-left-color: rgba($subsidiary, 0.2);
        }
    }

    .fc-divider {
        background-color: rgba($subsidiary, 0.2);
        border: 0;
        box-shadow: 0 1px 2px rgba($subsidiary, 0.2);
    }

    .fc-time-grid {
        .fc-now-indicator-line {
            border-top-width: 0;
            height: 2px;
            background-color: $primary;
            box-shadow: 0 1px 2px rgba($primary-dark, 0.4);
            right: calc(7% - 2px);
            left: -2px;
        }

        .fc-now-indicator-arrow {
            left: 2px;
            border: 0;
            width: 10px;
            height: 8px;
            border-radius: 2px;
            background-color: $primary;
            margin-top: -3px;
            box-shadow: 0 1px 2px rgba($primary, 0.4);

            &::before {
                display: block;
                margin-top: 3px;
                width: 18px;
                height: 2px;
                content: "";
                background-color: $primary;
                box-shadow: 0 1px 2px rgba($primary, 0.4);
            }

            &::after {
                position: absolute;
                z-index: -1;
                top: 5px;
                display: none;
                width: 17px;
                height: 0;
                content: "";
                width: 100vw;
                border-top: 2px dashed rgba($primary, 0.25);
            }

            //border-width: 8px 0 8px 11px;
            //margin-top: -8px;
            //border-color: $danger-dark;
            //border-top-color: transparent;
            //border-bottom-color: transparent;
       
        }
    }

    .fc-head {
        margin-bottom: 5px;

        .fc-head-container {
            border-radius: $border-radius;
            background-color: $gray-lightest;
        }

        td,
        th {
            border-top-width: 0;
            font-size: 14px;
            color: $body-color;

            //background-color: $gray-lightest;
            padding-top: 0.1em;
            padding-bottom: 0.1em;
            border-width: 0;
            border-left-width: 1px;
            border-right-width: 1px;
        }

        th {
            font-weight: 600;
        }

        .fc-row {
            border-width: 0 !important;
        }
    }

    .fc-bg {
        font-size: 14px;
        color: $subsidiary-lighter;
    }

    .fc-widget-content {
        border-top-width: 0;
        border-bottom-width: 0;
    }

    .fc-slats {
        font-size: 14px;
        color: $subsidiary-lighter;

        td {
            height: 2rem;
        }

        tr {
            &:nth-child(odd) {
                background-color: rgba($subsidiary, 0.05);
            }

            &:last-child {
                td,
                th {
                    border-bottom-width: 0;
                }
            }
        }

        .fc-axis {
            background-color: transparent;
        }
    }

    .fc-time-grid {
        .fc-event-container {
            margin-right: 7%;
            margin-left: 1px;
        }
    }

    .fc-event {
        border-width: 0;
        border-style: solid;
        border-radius: $border-radius-sm;
        z-index: 0;

        //border-left-width: 5px;
        border-top-width: 5px;
        border-bottom: 1px solid rgba($black, 0.05) !important;
        border-left: 1px solid rgba($black, 0.05) !important;
        border-right: 1px solid rgba($black, 0.05) !important;

        padding-left: 3px;
        background-color: rgba($white, 0.9);
        color: $body-color;
        margin-bottom: 7px;
        box-shadow: 1px 2px 3px rgba($subsidiary, 0.1);
        padding-bottom: 2px;

        &:hover {
            box-shadow: 1px 2px 5px rgba($subsidiary, 0.2);
        }

        .fc-content {
            overflow: hidden;
        }

        .fc-resizer {
            font-size: 13px;
            background-color: rgba($subsidiary-lightest, 0.6);
            color: $subsidiary;
            left: 2px;
            right: 2px;
            bottom: -5px;
            border-radius: 2px;
        }

        .fc-event-background {
            opacity: 0.2;
            position: absolute;
            left: 0;
            top: 0;
            right: 0;
            bottom: 0;
            display: none;
        }

        &.fc-free-event {
            background-color: rgba($white, 0.7);

            .fc-event-background {
                display: block;
            }
        }

        .fc-time {
            white-space: nowrap;
            overflow: hidden;

            > span {
                font-size: 13px;
                font-weight: 600;
                color: rgba($body-color, 0.55);
            }
        }

        .fc-status {
            position: relative;
            top: 2px;
            display: inline-block;
            margin-left: 4px;
            margin-right: 4px;
            height: 11px;
            min-width: 5px;
            border-radius: 11px;
            padding-left: 3px;
            padding-right: 3px;
            line-height: 11px;
            font-size: 13px;
            font-weight: 600;
            text-transform: uppercase;
        }

        .fc-status-code {
            display: block;
            letter-spacing: 0.5px;
            max-width: 30px;
            white-space: nowrap;
            overflow: hidden;
        }

        .fc-subcateogry-label {
            overflow-y: hidden;
            display: inline-block;
            min-width: 5px;
            border-radius: 7px;
            padding: 3px 5px 3px 5px;
            line-height: 11px;
            font-size: 10px;
            font-weight: 600;
        }

        .fc-title {
            font-weight: 600;
            font-size: 14px;
            color: $body-color;
            line-height: 1.2;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        .fc-content{
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }

    .fc-time-grid-event {
        //margin-bottom: 5px;
   
    }
}

tr#fullcalendar-sub-header {
    a {
        color: $white;
    }
}

/*a.fc-event{
    width: initial !important;
}*/

//fullcalendar width fix
a.fc-event {
    width: 90% !important;

    @include media-breakpoint-up(md) {
        width: initial !important;
    }
}

a.fc-day-grid-event {
    min-height: 35px;
}


//groupview optimized
.fc-resourceTimeGridDay-view .fc-bg {
    tbody td:nth-of-type(even) {
        //background:rgba(255,255,136,1);
    }
}

//setting if bookedSlotFullColor = 1
.booked-slot-fullcolor {
    .fc {
        .fc-event {
            background-color: rgba($white, 0.7);

            .fc-event-background {
                display: block;
            }
        }
    }
}

.priority-calendar {
    .fc-highlight {
        background-color: darkgray;
    }

    a.fc-event {
        cursor: pointer;
    }
}

.fc-axis.fc-week-number {
     text-align: center !important;
}