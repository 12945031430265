.modal-title {
    button {
        margin-left: 15px;
    }
}

.modal-appointment {
    .btn-video{
        display: flex;
        margin-bottom: .5rem;
        padding-top: 0;
        padding-bottom: 0;

        .btn-labeled {
            display: flex;
            text-align: left;
        }
        .btn-text {
            text-align: center;
            padding-top: .4rem;
            margin-bottom: 0;
        }
    }

    .modal-footer {
        display: block;


        @include media-breakpoint-down(xl) {
            button, div {
                width: 100%;
                margin-bottom: .5rem;

                &.btn-labeled {
                    display: flex;
                    text-align: left;
                }
                &.btn-text {
                    text-align: center;
                    padding-top: .4rem;
                    margin-bottom: 0;
                }
            }
        }
    }

    &-data-col {
        min-width: 150px;
    }

    .close {
        position: absolute;
        right: 1rem;
    }
}

.modal-buttons{
    display: flex;

    @include media-breakpoint-down(md) {
        flex-flow: wrap;
    }
    
    .btn{
        margin-right: 1rem;
        min-width: 125px;

        @include media-breakpoint-down(md) {
            width: calc(50% - 0.25rem) !important; //0.25 instead of 0.5
            min-width: 100px;
            margin-right: 0.5rem;
            overflow: hidden;
            padding-right: 2px;
            padding-left: 0;
            

            &:nth-child(even) {
                margin-right: 0;
            }

            .btn-label{ left: 0; }
            .btn-text{
                text-overflow: ellipsis;
                overflow: hidden;
            }  
        }

        &.last-child,
        &:last-child {
            margin-right: 0;
        }
    }
}
.statuslogInfoBtn{ cursor: pointer; }