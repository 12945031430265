

@import url(//fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,600,700,900,400italic);

$font-family: 'Source Sans Pro', sans-serif;

// Cutoms Gray colors for theme
$gray-darker:           #26314A; //#232735;
$gray-dark:             #55657F; //#3a3f51;
$gray:                  #dde6e9;
$gray-light:            #e3e7ea;
$gray-lighter:          #dce7ed;
$gray-lightest:         #edf2f9;
$white:                 #ffffff;

$subsidiary:           #55657F; // #01375F;
$subsidiary-light:     #73829B;// #033F6C;
$subsidiary-lighter:   #94A0B2; // #4582ad;
$subsidiary-lightest:  #ECF3FF; // #96B2C8;
$subsidiary-dark:      #26314A; // #002230;

// Custom color variants
$amountOfLight: 10%;
$amountOfLightest: 35%;

// Custom brand colors for theme
$inverse:               #131e26;
$inverse-light:         lighten($inverse, $amountOfLight);
$inverse-dark:          darken($inverse, $amountOfLight);

$purple:                #7266ba;
$purple-light:          lighten($purple, $amountOfLight);
$purple-dark:           darken($purple, $amountOfLight);

$pink:                  #f532e5;
$pink-light:            lighten($pink, $amountOfLight);
$pink-dark:             darken($pink, $amountOfLight);

$yellow:                #fad732;
$yellow-light:          lighten($yellow, $amountOfLight);
$yellow-dark:           darken($yellow, $amountOfLight);

$green:                 #37bc9b;
$green-light:           lighten($green, $amountOfLight);
$green-dark:            darken($green, $amountOfLight);

$brand:               #FF1A6B;
$brand-light:         lighten($brand, $amountOfLight);
$brand-dark:          darken($brand, $amountOfLight);

$primary:               #F95C1E; // #009FE3;
$primary-light:         #FF7F44; //lighten($primary, $amountOfLight);
$primary-lighter:       lighten($primary, 25%);
$primary-lightest:      #F9C0AA; //lighten($primary, 50%);
$primary-dark:          #E54607; //darken($primary, $amountOfLight);

$secondary:             #FF8C00;
$secondary-light:         lighten($secondary, $amountOfLight);
$secondary-dark:          darken($secondary, $amountOfLight);

$success:               #27c24c;
$success-light:         lighten($success, $amountOfLight);
$success-lightest:      lighten($success, 48%);
$success-dark:          darken($success, $amountOfLight);

$info:                  #23b7e5;
$info-light:            lighten($info, $amountOfLight);
$info-lightest:         lighten($info, 48%);
$info-dark:             darken($info, $amountOfLight);
/*
$info:                  $primary;
$info-light:            $primary-light;
$info-lightest:         $primary-lightest;
$info-dark:             $primary-dark;*/

$warning:               #FF7F44;
$warning-light:         lighten($warning, $amountOfLight);
$warning-dark:          darken($warning, $amountOfLight);
$warning-lightest:       lighten($warning, $amountOfLightest);

$danger:                #f05050;
$danger-light:          lighten($danger, $amountOfLight);
$danger-lightest:       lighten($danger, $amountOfLightest);
$danger-dark:           darken($danger, $amountOfLight);

$dark:                  #3a3f51;


// Theme variables

// used for sidebars and content spacing
$navbar-height:                    65px;

// Button secondary redefinition style for theme
$btn-secondary-color:              #333;
$btn-secondary-bg:                 $white;
$btn-secondary-border:             #eaeaea;

// Top Border color for custom default cards (.card-default)
$card-default-border-color:        $subsidiary-lightest; //#cfdbe2;
$card-cap-bg: $gray-lightest;


// ---------------------
// Utilities variables
// ---------------------

$text-alpha:            rgba(255,255,255,.5);
$text-alpha-inverse:    rgba(0,0,0,.5);

$font-size-base:        1rem;
$text-sm:               $font-size-base * 0.74375;
$text-md:               $font-size-base * 1.6625;
$text-lg:               $font-size-base * 2.625;

$border-color:          rgba(0,0,0,.12);

$wd-xxs:                60px;
$wd-xs:                 90px;
$wd-sm:                 150px;
$wd-sd:                 200px; // sd == standard
$wd-md:                 240px;
$wd-lg:                 280px;
$wd-xl:                 320px;
$wd-xxl:                360px;
$wd-wide:               100%;
$wd-auto:               auto;
$wd-zero:               0;


// ---------------------
// Bootstrap 4 Variables Override
// ---------------------

// Color system

$colors: (
    'white':      $white,
    "purple":     $purple,
    "pink":       $pink,
    "green":      $green,

    "brand":    $brand,
    'brand-light': $brand-light,
    'brand-dark':  $brand-dark,

    "primary":    $primary,
    'primary-light': $primary-light,
    'primary-dark':  $primary-dark,

    "secondary":  $secondary,
    'secondary-light': $primary-light,
    'secondary-dark':  $primary-dark,

    "subsidiary": $subsidiary,
    'subsidiary-light': $subsidiary-light,
    'subsidiary-lightest': $subsidiary-lightest,
    'subsidiary-dark':  $subsidiary-dark,

    "success":    $success,
    'success-light': $success-light,
    'success-dark':  $success-dark,

    "info":       $primary,
    'info-light':    $primary-light,
    'info-dark':     $primary-dark,

    "warning":    $warning,
    'warning-light': $warning-light,
    'warning-dark':  $warning-dark,

    "danger":     $danger,
    'danger-light':  $danger-light,
    'danger-dark':   $danger-dark,

    'green-light':   $green-light,
    'green-dark':    $green-dark,

    'pink-light':    $pink-light,
    'pink-dark':     $pink-dark,

    'purple-light':  $purple-light,
    'purple-dark':   $purple-dark,

    'inverse':    $inverse,
    'inverse-light': $inverse-light,
    'inverse-dark':  $inverse-dark,

    "yellow":     $yellow,
    'yellow-light':  $yellow-light,
    'yellow-dark':   $yellow-dark,
);


$theme-colors: (
  "brand":      $brand,
  "primary":    $primary,
  "secondary":  $secondary,
  "default":    $gray,
  "subsidiary": $subsidiary,
  "success":    $success,
  "info":       $info,
  "warning":    $warning,
  "danger":     $danger,
  "yellow":     $yellow
);

// Body

$body-bg:                   $white;
$body-color:                $gray-darker;

$border-radius:               .4rem; //.25rem;
$border-radius-sm:             .25rem; //.25rem;
$border-radius-lg:            .55rem; //.375rem;

$line-height-base:            1.528571429;

$headings-line-height:        1.1;

$text-muted:                  #909FA7;

// Tables

$table-accent-bg:             rgba($gray-lightest, 0.25);
$table-hover-bg:              $gray-lightest;
$table-border-color:          $gray-lighter;

// Buttons + Forms

$input-btn-padding-y:         .5rem; //.375rem;
$input-btn-padding-x:         1rem;

$input-btn-padding-y-sm:      .3125rem;
$input-btn-padding-x-sm:      .625rem;

$input-btn-padding-y-lg:      .625rem;
$input-btn-padding-x-lg:      1.25rem;

$input-height:                2.1875rem; // 35px

// Forms

$input-disabled-bg:                     $gray-lighter;
$input-border-color:                    $gray;
$input-border-width:                    1px;
$input-focus-border-color:              $primary;
$input-placeholder-color:               #b7bac9;

$form-feedback-icon-valid:              none;
$form-feedback-icon-invalid:            none;

// Dropdowns

$dropdown-border-color:             #e1e1e1;
$dropdown-padding-y: 0;
$dropdown-border-width: 0;

// Navs

$nav-tabs-link-active-bg:           #fff;

// Jumbotron

$jumbotron-bg:                      #fcfcfc;

// List group

$list-group-border-color:           #e4eaec;

// Image thumbnails

$thumbnail-padding:                 .125rem;


// Breadcrumbs

$breadcrumb-bg:                     #fafafa;
$breadcrumb-divider-color:          #c1c2c3;
$breadcrumb-active-color:           $body-color;


