.notification {
    &-info-outline {
        border: 1px solid $info;
        padding: 5px 10px;
        margin-top: 5px;
    }
    &-success-outline {
        border: 1px solid $success;
        padding: 5px 10px;
        margin-top: 5px;
    }
    &-warning-outline {
        border: 1px solid $warning;
        padding: 5px 10px;
        margin-top: 5px;
    }
    &-danger-outline {
        border: 1px solid $danger;
        padding: 5px 10px;
        margin-top: 5px;
    }

    .content {
        margin-top: .6rem;
        display: flex;

        div {
            min-width: 15%;
        }
    }
}

.alert {
    &-default {
        color: $black;
        background-color: $gray-200;
    }

    .alert-content  {
        margin-top: .6rem;
        display: flex;

        div {
            min-width: 15%;
        }
    }
}