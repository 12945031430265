.quantity-tag {
    cursor: pointer;
};

.quantity-field {
    max-width: 48px;
};

.search-bar {
    width: 100%;
}

.product-category {
    color: white !important;
    margin-top: 4px;
    margin-bottom: 4px;
}

.header-button {
    margin-right: 10px;
}

.payment-input-field {
    height: 40px !important;
}

.cashdesk-return-button {
    margin-bottom: 10px;
}