
.react-select {
    .react-select__control--is-focused {
        border-color: $primary;
        box-shadow: 0 0 0 0.2rem rgba($primary, 0.25);

        &:hover, &:focus {
            border-color: $primary;
        }
    }

    .react-select__option {
        &:hover {
            color: $body-color;
            background-color: $primary-lightest;
        }
    }

    .react-select__option--is-focused {
        background-color: $primary-lightest;

    }

    .react-select__option--is-selected {
        background-color: $primary;
        color: $white;

        &:hover {
            color: $white;
            background-color: $primary-dark;
        }
    }
}