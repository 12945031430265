.pagination {}

.page-item {
     
    & + & {
        margin-left: 3px;
    }
}

.page-link {
    border-width: 0;
    border-radius: $border-radius-sm;
    font-weight: 600;

    &:hover, &:focus {
        background-color: $gray-lightest;
    }

    .page-item.active & {
        box-shadow: 0 2px 3px rgba($primary, 0.45);

        &:hover, &:focus {
            background-color: $primary-dark;
        }
    }
}
