
.react-contexify {
    border-radius: $border-radius;

    .react-contexify__item {

        &:not(.react-contexify__item--disabled):hover > .react-contexify__item__content {
          background-color: $primary;
        }
    }

    .react-contexify__submenu{
      max-height: 245px;
      overflow-y: scroll;
      overflow-x: hidden;
      margin-top: 0;
      margin-bottom: 0;
      padding-top: 0;
      padding-bottom: 0;
    }
}