.mb-mails {
  tr {
    cursor: pointer;
    
    td {
      border-bottom: 1px solid $gray;
    }

    &.selected {
      background-color: $gray-lightest;
    }
  }
}

.mailbox-message-conversation-item {
  margin-bottom: 20px;

  div {
    border-left: 1px solid $gray-light;
    padding-left: 15px;
  }
}
