/* ========================================================================
     Component: nestable
 ========================================================================== */

$color-hover:  #f7f7f7;

.dd {
    position: relative;
    display: block;
    margin: 0;
    padding: 0;
    list-style: none;
    font-size: 13px;
    line-height: 20px;
}

.dd-list {
    display: block;
    position: relative;
    margin: 0;
    padding: 0;
    list-style: none;
}

.dd-list .dd-list {
    padding-left: 30px;
}

.dd-collapsed .dd-list {
    display: none;
}

.dd-item,
.dd-empty,
.dd-placeholder {
    display: block;
    position: relative;
    margin: 0;
    padding: 0;
    min-height: 20px;
    font-size: 13px;
    line-height: 20px;
}

.dd-handle {
    display: block;
    margin: 5px 0;
    padding: 10px 10px;
    text-decoration: none;
    border: 1px solid #ebebeb;
    background: #fff;
    -webkit-border-radius: 3px;
    border-radius: 3px;
}

.dd-handle:hover {
    background: #fff;
}

.dd-item>button {
    display: block;
    position: relative;
    cursor: pointer;
    float: left;
    width: 25px;
    height: 30px;
    margin: 5px 0;
    padding: 0;
    text-indent: 100%;
    white-space: nowrap;
    overflow: hidden;
    border: 0;
    background: transparent;
    font-size: 12px;
    line-height: 1;
    text-align: center;
    font-weight: bold;
}

.dd-item>button:before {
    content: '+';
    display: block;
    position: absolute;
    width: 100%;
    text-align: center;
    text-indent: 0;
}

.dd-item>button[data-action="collapse"]:before {
    content: '-';
}

.dd-placeholder,
.dd-empty {
    margin: 5px 0;
    padding: 0;
    min-height: 30px;
    background: #f2fbff;
    border: 1px dashed #b6bcbf;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
}

.dd-empty {
    border: 1px dashed #bbb;
    min-height: 100px;
    background-color: #e5e5e5;
    background-size: 60px 60px;
    background-position: 0 0, 30px 30px;
}

.dd-dragel {
    position: absolute;
    pointer-events: none;
    z-index: 9999;
}

.dd-dragel>.dd-item .dd-handle {
    margin-top: 0;
}

.dd-dragel .dd-handle {
    -webkit-box-shadow: 2px 4px 6px 0 rgba(0, 0, 0, .1);
    box-shadow: 2px 4px 6px 0 rgba(0, 0, 0, .1);
}

// Nestable Extras
.nestable-lists {
    display: block;
    clear: both;
    padding: 30px 0;
    width: 100%;
    border: 0;
    border-top: 2px solid #ddd;
    border-bottom: 2px solid #ddd;
}

@media only screen and (min-width: 700px) {

    .dd+.dd {
        margin-left: 2%;
    }
}

.dd-hover>.dd-handle {
    background: #2ea8e5 !important;
}

// Nestable Draggable Handles
.dd3-content {
    display: flex;
    justify-content: space-between;
    margin: 5px 0;
    padding: 10px 10px 10px 50px;
    text-decoration: none;
    border: 1px solid #ebebeb;
    background: #fff;
    -webkit-border-radius: 3px;
    border-radius: 3px;

    &-start, &-end {
        flex: 0 1 auto;
    }

    &:hover {
        background-color: $color-hover;
    }
}

.dd-dragel>.dd3-item>.dd3-content {
    margin: 0;
}

.dd3-item>button {
    margin-left: 40px;
}

.dd3-handle {
    position: absolute;
    margin: 0;
    left: 0;
    top: 0;
    cursor: pointer;
    width: 40px;
    text-indent: 100%;
    white-space: nowrap;
    overflow: hidden;
    border: 1px solid #ebebeb;
    background: #fff;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    height: 100%;
}

.dd3-handle:before {
    content: '≡';
    display: block;
    position: absolute;
    left: 0;
    top: 10px;
    width: 100%;
    text-align: center;
    text-indent: 0;
    color: #ccc;
    font-size: 20px;
    font-weight: normal;
}

.dd3-handle:hover {
    background: $color-hover;
}
