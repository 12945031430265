.fc.fc-unthemed {
    .fc-event {
        .fc-title {
            white-space: normal;

            float: left;
            margin-right: .2rem;
        }

        &.fc-short {
            overflow: hidden;

            .fc-time {
                float: left;
            }

            .fc-title {    
                float: none;
            }
        }
    }

    .fc-breakhour {
        border-top: 3px solid $black !important;
    }
}
