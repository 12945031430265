@import '~@fullcalendar/core/main.css';
@import '~@fullcalendar/daygrid/main.css';
@import "~@fullcalendar/timegrid/main.css";

div.close-event-button {
    position: absolute;
    top: 5px;
    right: 10px;
    z-index: 999;

    a {
        color: #000000;
    }
}

.context-menu {
    z-index: 999;

    &-item {
        margin-right: 5px;
    }
}

a.fc-event {
    width: 100%;
    
    .fc-content {
        overflow: initial;
    }
}

div.event-priority {
    width: 50px;
    background: white;
    color: black;
    display: block;
    float: left;
    margin-right: 10px;
    text-align: center;
    border: 1px solid lightgray;
    position: relative;
    cursor: pointer;
    padding-right: 5px;
    
    i {
        position: absolute;
        top: 3px;
        right: 5px;
    }
}

ul.event-priority-list {
    list-style: none;
    padding: 0;
    display: none;
    z-index: 999;

    li {
        cursor: pointer;
        width: 50px;
        background: white;
        color: black;
        padding-right: 5px;
        text-align: right;

        &:hover {
           background: rgb(235, 235, 235); 
        }
    }
}