/* ========================================================================
     Component: cards
 ========================================================================== */

.card {
    margin-bottom: 1.25rem; //20px
}

//
// Adds support to use more components inside cards
//
.card {
    .table {
        margin-bottom: 0;
        >thead>tr>th {
            border-top: 0;
        }
    }
    &.card-transparent {
        border: 0;
        background-color: transparent;
        box-shadow: 0 0 0 #000;
        .card-header,
        .card-body {
            background-color: transparent;
            padding-left: 0;
            padding-right: 0;
        }
    }
}


.card-flat {
    margin: 0 !important;
    border: 0;
}

.card-columns-2 {
    column-count: 1;
    @include media-breakpoint-up(md) {
        column-count: 2;
    }
}

.card-tool {
    display: inline-block;
    color: #fff;
    text-align: center;
    &:hover {
        cursor: pointer;
    }
    > em + em {
        margin-left: 12px;
    }
}

.card-header {

    .card-default & {
        >.card-tool {
            color: #c1c2c3;
        }
    } // right floated labels adjust position
    >.badge.float-right {
        margin-top: 3px;
        +.badge.float-right {
            margin-right: 10px;
        }
    }

    &.card-header-primary {
        background-color: $primary;
        color: $white;
    }

    .card-title {
        font-weight: 600;
    }
}

.card-footer {
    box-shadow: inset 0 2px 2px rgba($black, 0.05);

    .pagination {
        margin: 0;
    }
    .radial-bar {
        margin-bottom: 0;
    }
    p {
        margin-bottom: 0;
    }
}
