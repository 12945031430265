.profile-footer {
  background-color: $white;
  border-top: 2px solid $gray-lightest;
  z-index: 1;
  padding: 25px;
}

.profile-sidebar-block {
  padding-left: 15px;
  padding-right: 15px;

  margin-bottom: 20px;
}

.category-container, .workload-container {
  .category-item, .workload-item {
    float: left;
    margin-right: 10px;
    margin-bottom: 10px;
    text-align: center;
    border-radius: 10px;
    padding: 5px 10px;
    cursor: pointer;

    // width: 100%;
  }

  .categories-group, .workload-group {
    padding-left: 15px;
    padding-right: 15px;

    &-title {
      width: 100%;
    }
  }
}

.heading-end {
  .category-item-selected {
    margin-right: 10px;
    text-align: center;
    border-radius: 10px;
    padding: 6px 10px;
  }
}
