/* ========================================================================
     Component: bootstrap-reset
 ========================================================================== */

// Use this file to reset simple bootstrap styles
// instead of modifying the core files
// Makes easy to update new framework versions
// --------------------------------------------------

*:not(.react-grid-Cell):focus {
    outline: 0 !important;
}

a {
    outline: none !important;

    &.text-muted {
        &:hover,
        &:focus {
            color: darken($text-muted, 10%);
        }
    }
}

hr {
    border-top: 1px solid $gray-light;
}

// custom element
[data-now] {
    display: inline-block;
}

.badge {
    padding: 0.1875rem 0.4375rem;
    font-size: 0.75rem;
    font-weight: 600;

    //border-radius: 0.625rem;

    &.badge-warning {
        color: #fff !important;
    }

    &.badge-secondary {
        background-color: $gray-dark;
        color: #fff;
    }
}

.list-group {
    line-height: 1.3;

    .list-group-item {
        padding: 10px;
        color: #555;

        &.active {
            color: #fff;

            .badge {
                background-color: #fff;
                color: $primary;
            }
        }

        .card>& {
            border: 0;
        }
    }
}

.list-group+.card-footer {
    border-top: 0;
}

.page-header {
    padding-bottom: 0.59375rem;
    margin: 2.625rem 0 1.3125rem;
    border-bottom: 1px solid $gray-light;
}

.card {
    border-color: transparent;

    .card-header {
        border-bottom: 0;
        padding: 0.625rem 0.9375rem;
        background-color: transparent;

        .card-title {
            margin-bottom: 0;
            font-size: 1rem;
        }

        a {
            text-decoration: none !important;
        }
    }
}

.card-body {
    padding: 0.9375rem; //15px
}

.card-footer {
    padding: 0.625rem 0.9375rem;
}

.card-default {
    //border-top-width: 3px;
    border-color: $card-default-border-color;

    .card-header {
        background-color: #fff;
    }
}

.well {
    border: 1px solid $gray;
}

.jumbotron {
    border: 1px solid $gray;
    background-color: #fff;

    @include media-breakpoint-up(md) {
        padding: $jumbotron-padding ($jumbotron-padding / 2);
    }
}

.nav-tabs>.nav-item>.nav-link {
    font-weight: bold;
    color: $body-color;
    background-color: $gray-lighter;
    margin: 0;
    border: 1px solid $gray;
    border-radius: 0;
    padding: 10px 20px;

    &.active {
        background-color: $nav-tabs-link-active-bg;

        &,
        &:hover,
        &:focus {
            //color: inherit;
            border-bottom-color: #fff;
        }
    }
}

.tab-content {
    padding: 10px 20px;
    border-style: solid;
    border-width: 0 1px 1px 1px;
    border-color: $gray;

    .nav-pills+& {
        border: 0;
        padding: 0;
    }

    .p-0 & {
        padding: 0 !important;
    }
}

.btn {
    border-radius: 5px;
    font-size: 15px;
    font-weight: 500;
    -webkit-appearance: none;
    outline: none !important; // padding: 0.375rem 1rem;
    @include transition(all 0.1s);

    &.btn-link {
        box-shadow: none;
        border: 0;
    }

    .input-group & {
        font-size: 14px;
        border-color: $input-border-color;
    }

    .input-group .form-control-sm+.input-group-btn & {
        font-size: 0.8125rem;
        padding: 0.3125rem 0.625rem;
        margin-left: -1px;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }
}

// Override button styles
.btn {
    &.btn-warning {
        color: #fff;
    }

    &.btn-outline-warning {
        @include hover-focus-active {
            color: #fff;
        }
    }
}

.btn-default {
    background-color: $white;
    border-color: $gray;
    color: $body-color;

    &:hover, &:focus {
        color: $body-color;
        background-color: $gray-lightest;
    }
}

span.btn {
    -webkit-appearance: none !important;
}

// FORMS
.form-control {
    //box-shadow: 0 0 0 #000 !important;
    font-size: 0.875rem; //14px
    &:focus {
        border-color: $input-focus-border-color;
    }
}

.form-control-sm,
select.form-control-sm {
    font-size: .75rem;
    height: 31px;
}

.custom-select {
    -webkit-appearance: none;
}

.custom-control-label::before {
    top: 0.19rem;
    border: 1px solid $gray;
}

fieldset {
    padding-bottom: 20px;
    border-bottom: 1px dashed #eee;
    margin-bottom: 20px;

    &.last-child,
    &:last-child {
        border-bottom: 0;
    }

    .form-group {
        margin-bottom: 0;
    }
}

.input-group-text {
    font-size: .875rem; // 14px
}

@include media-breakpoint-down(sm) {
    // Remove Input Shadows on iPad
    input[type="text"],
    input[type="email"],
    input[type="search"],
    input[type="password"] {
        -webkit-appearance: none;
    }
}


// TABLES
.table-responsive {
    overflow-y: hidden;
}

.table {
    border-collapse: separate;
    border-spacing: 0;

    > thead {
        > tr {
            > td,
            > th {
                background-color: $subsidiary-lightest;
                color: $body-color;
                border-color: $subsidiary;
                border-top: 0;
                border-bottom: 3px solid $white;
                border-left: 0;
                border-right: 0;

                &:first-child {
                    border-top-left-radius: $border-radius;
                    border-bottom-left-radius: 0.45em;
                }

                &:last-child {
                    border-top-right-radius: $border-radius;
                    border-bottom-right-radius: 0.45em;
                }

                position: relative;
            }

            > th {
                font-weight: 600;
            }
        }
    }

    > tbody {
        > tr {
            > td,
            > th {
                border-left: 0;
                border-top: 0;
            }
        }
    }

    // Bottom align for column headings
    >thead>tr>th {
        //border-bottom-width: 0;
   
    } // Account for multiple tbody instances
    >tbody+tbody {
        border-bottom-width: 1px;
    }

    .form-control {
        max-width: 100%;
    }
}

.table-bordered {
    border-left: 0;
    border-right: 0;
    border-top: 0;
    border-bottom: 0;

    th, td {
        border-color: $gray-lighter;
    }

    td:first-child,
    th:first-child {
        border-left: 0;
    }

    td:last-child,
    th:last-child {
        border-right: 0;
    }

    tbody tr:last-child {
        td, th {
            border-bottom: 0;
        }
    }
}

table.table-bordered.dataTable {
    > tbody {
        td, th {
            border-bottom: 1px solid $gray-lighter !important;
        }
    }
}

tr {
    &.table-danger {

        > td:first-child {
            border-left: 4px solid $danger;
        }

        > td,
        > th {
            background-color: $danger-lightest;
            border-color: rgba($danger, 0.1);
        }
    }

    &.table-warning {

        > td:first-child {
            border-left: 4px solid $warning;
        }

        > td,
        > th {
            background-color: $warning-lightest;
            border-color: rgba($warning, 0.1);
        }
    }

    &.table-success {

        > td:first-child {
            border-left: 4px solid $success;
        }

        > td,
        > th {
            background-color: $success-lightest;
            border-color: rgba($success, 0.1);
        }
    }

    &.table-info {

        > td:first-child {
            border-left: 4px solid $info;
        }

        > td,
        > th {
            background-color: $info-lightest;
            border-color: rgba($info, 0.1);
        }
    }
}

.progress {
    @include box-shadow(0 0 0 #000);

    border-radius: $border-radius-sm;
    border: 1px solid $progress-bg;
    background-color: #fff;

    .progress-bar {
        @include box-shadow(0 0 0 #000);
    }
}

// Popover
// --------------------------------------------------
.popover {
    box-shadow: 0 0 0 #000;
    border-color: #eee;
    border-bottom: 2px solid $gray-light;
    border-radius: $border-radius-sm;

    .popover-title {
        border: 0;
    }
}

// NAV
.nav {
    &.nav-pills {
        .active>a {
            background-color: $primary;
        }
    }
}

// DROPDOWN
.dropdown-menu {
    border-radius: $border-radius-sm;
    font-size: 14px;
    padding: 0;
    box-shadow: rgba(0, 0, 0, 0.176) 0px 6px 12px;
    border-color: $dropdown-border-color;

    .dropdown-item {
        line-height: 1.52857143;
        padding: 0.1875rem 1.25rem;
    }
}

.dropdown-header {
    color: #a1a2a3;
}

.navbar-top .navbar-nav>.active>a {
    color: #999;

    &:hover,
    &:focus {
        color: #d1d2d3;
    }
}

.navbar-default .navbar-nav .open .dropdown-menu>li>a {
    color: #666;

    &:hover,
    &:focus {
        color: #222;
    }
}

// CAROUSEL
.carousel {
    .carousel-indicators {
        bottom: 0;
    }

    .carousel-control {
        &.left,
        &.right {
            background-image: none;
        } // Add support for font awesome
        em {
            position: absolute;
            top: 50%;
            left: 50%;
            z-index: 5;
            display: inline-block;
            width: 20px;
            height: 20px;
            margin-top: -10px;
            margin-left: -10px;
        }
    }
}

// ALERT
@each $color, $value in $theme-colors {
    .alert-#{$color} {
        @include alert-variant($value, $value, #fff);
    }
}

.alert-secondary {
    color: $body-color;
    border-color: $gray-light;
}

// fix for ie9 (dropdwon no showing)
.topnavbar,
.navbar,
.navbar .dropdown-menu {
    filter: none !important;
}
