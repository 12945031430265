.order-table {
    tr.muted {
        background-color: $gray-lightest;
    }
}

th.sortable {
    cursor: pointer;
    span {
        float: right;
    }
}

#navigation-dt-picker {
    .form-control {
        width: 100px;
        border-radius: 0;
        margin-left: 4px;
        background-color: #fff;
        text-align: center;
        font-weight: 600;
        cursor: pointer;
        padding-left: 8px;
        padding-right: 8px;
        height: 2.5rem;
    }
}
